import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <section class="header">
        <div class="header-row">
          <div class="logo">ando</div>
        </div>
      </section>
      <div class="pageInfo">
        <h1>Website Under Construction</h1>
        <p>Launching Soon</p>
      </div>
    </div>
  );
}

export default App;
